<template>
    <Loading :isLoading="isLoading" />
    <div class="w-full h-full px-5 md:px-5 lg:px-10 pt-2 md:pt-5 lg:max-h-screen mb-5">

        <h1 class="primary-text font-semibold text-2xl md:text-2xl text-thin mt-2 mb-5 md:mb-7"> Datos de Crédito </h1>

        <CreditOwner ref="creditOwnerRefs" :validations="rules_validations" :validations_birth_place_owner="rules_validations_birth_place_owner" :validations_birth_place_payer="rules_validations_birth_place_payer" :view_class_view="view_class_view"/>
        
        <DetailComponent v-if="accountInformation != null && creditOwnerRefs != null && creditOwnerRefs.creditOwnerModel.credit_owner == 'para mí' && creditOwnerRefs.creditOwnerModel.payer == 'por mí'" class="mt-10" :rfc="accountInformation.rfc" :curp="accountInformation.curp" :gender="accountInformation.gender" :age="accountInformation.age" :birth_place="accountInformation.birth_place" :cellphone="accountInformation.cellphone" :telephone="accountInformation.telephone" :email="accountInformation.email"/>
        
        <div class="w-full">
            <button id="button_continue" @click="next('/cuenta/credito/documentos')" class="md:w-1/3 block bg-mustardDark cursor-pointer px-5 py-4 mx-auto my-8 h-14 md:h-14 rounded text-center font-normal text-xl text-white"> Continuar </button>       
        </div>

    </div>
</template>

<script>

import { ref, onMounted, onBeforeMount, getCurrentInstance } from 'vue';
import { useRouter } from 'vue-router';
import { helpers, requiredIf, maxLength, minLength, email } from "@vuelidate/validators";

import User from "@/classes/User";
import { getAdmissionRequestOrCreate } from "@/api/user";

import Loading from '@/components/Loading/VueLoading.vue';
import DetailComponent  from "@/components/Cuenta/Credit/DetailComponent";

import Swal from "sweetalert2";
import SwalAlertHelper from '@/helpers/swal-alert';
import { getTokenDecoden } from '@/helpers/tokenauth';

import moment from 'moment';
export default {

    name: 'CreditOwnerComponent',
    setup(){
        
        const router = useRouter();

        const userObjectClass = ref(null);

        const accountInformation = ref();

        let app = getCurrentInstance();

        let isLoading = ref(true);

        let creditOwnerRefs = ref(null);

        let view_class_view = {
            form_container: "",
            input_container: "",
            class_inputs:{
                valid:"border-blue-600",
                no_valid:"border-red-600",
                default:"w-full py-2 px-2 rounded-md border-2 border-sm focus:border-blue-600 mb-2 mt-2 shadow-md focus:outline-none "
            },
            div_container_error:{
                container:"my-2",
                text_error:"text-red-600",
            },
        };

        let rules_validations = ref({
            payer: {
                required: helpers.withMessage('El campo es requerido', requiredIf((value) => {
                    return true;
                }))
            },
            credit_owner: {
                required: helpers.withMessage('El campo es requerido', requiredIf((value) => {
                    return true;
                }))
            },
            owner_rfc: {
                required: helpers.withMessage('El campo es requerido', requiredIf(() => {
                    return creditOwnerRefs.value.creditOwnerModel.credit_owner == 'otra persona';
                })),
                maxlength: helpers.withMessage("El campo tiene que tener maximo 13 caracteres",maxLength(13)),
                minlength: helpers.withMessage("El campo tiene que tener maximo 13 caracteres",minLength(13)),
                structureIsValid: helpers.withMessage("El formato del RFC no es correcto. Ejemplo de formato correcto: LOMP8206281H0" , helpers.withAsync(async function (value, e) {
                    if(value.length == 0) {
                        return true;
                    }

                    let rfc = validateRfcStructure(value);
                    if(!rfc.status) return true;
                    
                    let validate = await validateStrucDatauserRFC();
                    if(validate && e.owner_curp == ""){
                        e.owner_curp = value.toString().substr(0, 10);
                    }

                    return validate;
                })),
                isValid: helpers.withMessage("El formato del RFC no es correcto. Ejemplo de formato correcto: LOMP8206281H0" , helpers.withAsync(async function (value) {
                    if(value.length==0) {
                        return true;
                    }
                    let rfc = validateRfcStructure(value);
                    return rfc.status;
                }))
            },
            owner_curp: {
                required: helpers.withMessage('El campo es requerido', requiredIf((value, e) => {
                    return e.credit_owner == 'otra persona';
                })),
                minlength: helpers.withMessage("El campo tiene que tener mínimo 18 caracteres",minLength(18)),
                maxlength: helpers.withMessage("El campo tiene que tener máximo 18 caracteres",maxLength(18)),
                structureIsValid: helpers.withMessage("El formato del CURP no es correcto. Ejemplo de formato correcto: LOMP8206281HSRTZR02" , function (value) {
                    return value == ""? true : validateCurpStructure(value.toString().toUpperCase());
                }),
                validateMatchBetweenCurpAndRfc: helpers.withMessage(`Los primeros 10 caracteres del campo CURP no coinciden con los primeros 10 caracteres del campo RFC`, function (value, e) {
                    return (e.owner_rfc != '') ? validateMatchStrings(value, e.owner_rfc): true
                }),
            },
            owner_first_name: {
                required: helpers.withMessage('El campo es requerido', function(value, e) {
                    let rfc = validateRfcStructure(e.owner_rfc);
                    if(rfc.status) {
                        validateStrucDatauserRFC()
                    }
                    return !(e.credit_owner == 'otra persona' && value=='');
                }),
                minlength: helpers.withMessage("El campo tiene que tener mínimo 2 caracteres",minLength(2)),
                maxlength: helpers.withMessage("El campo tiene que tener máximo 30 caracteres",maxLength(30)),
            },
            owner_second_name: {
                required: helpers.withMessage('El campo es requerido', function(value, e) {
                    let rfc = validateRfcStructure(e.owner_rfc);
                    if(rfc.status) {
                        validateStrucDatauserRFC()
                    }
                    return true;
                }),
                minlength: helpers.withMessage("El campo tiene que tener mínimo 2 caracteres",minLength(2)),
                maxlength: helpers.withMessage("El campo tiene que tener máximo 30 caracteres",maxLength(30)),
            },
            owner_maternal_name: {
                required: helpers.withMessage('El campo es requerido', function(value, e) {
                    let rfc = validateRfcStructure(e.owner_rfc);
                    if(rfc.status) {
                        validateStrucDatauserRFC()
                    }
                    return !(e.credit_owner == 'otra persona' && value=='');
                }),
                minlength: helpers.withMessage("El campo tiene que tener mínimo 2 caracteres",minLength(2)),
                maxlength: helpers.withMessage("El campo tiene que tener máximo 30 caracteres",maxLength(30)),
            },
            owner_paternal_name: {
                required: helpers.withMessage('El campo es requerido', function(value, e) {
                    let rfc = validateRfcStructure(e.owner_rfc);
                    if(rfc.status) {
                        validateStrucDatauserRFC()
                    }
                    return !(e.credit_owner == 'otra persona' && value=='');
                }),
                minlength: helpers.withMessage("El campo tiene que tener mínimo 2 caracteres",minLength(2)),
                maxlength: helpers.withMessage("El campo tiene que tener máximo 30 caracteres",maxLength(30)),
            },
            owner_gender: {
                required: helpers.withMessage('El campo es requerido', requiredIf((value, e) => {
                    return e.credit_owner == 'otra persona';
                }))
            },
            owner_contact_email: {
                required: helpers.withMessage('El campo es requerido', requiredIf((value, e) => {
                    return e.credit_owner == 'otra persona';
                })),
                minlength: helpers.withMessage("El campo tiene que tener mínimo 5 caracteres",minLength(5)),
                maxlength: helpers.withMessage("El campo tiene que tener máximo 255 caracteres",maxLength(255)),
            },
            owner_contact_cellphone: {
                required: helpers.withMessage('El campo es requerido', requiredIf((value, e) => {
                    return e.credit_owner == 'otra persona';
                })),
                minLength: helpers.withMessage(`El campo debe contener mínimo 10 caracteres` , function(value){
                    if(value=="") return true;

                    value = value.toString().replace("-", "");
                    value = value.toString().replace(" ", "");
                    
                    if(value.toString().length < 10){
                    return false
                    }
                    return true
                }),
                maxLength: helpers.withMessage(`El campo debe contener máximo 10 caracteres` , function(value){
                    value = value.toString().replace("-", "");
                    value = value.toString().replace(" ", "");
                    if(value.toString().length > 10) return false;
                    return true
                }),
            },
            owner_contact_telephone: {
                required: helpers.withMessage('El campo es requerido', requiredIf((value, e) => {
                    return e.credit_owner == 'otra persona';
                })),
                minLength: helpers.withMessage(`El campo debe contener mínimo 10 caracteres` , function(value){
                    if(value=="") return true;

                    value = value.toString().replace("-", "");
                    value = value.toString().replace(" ", "");
                    
                    if(value.toString().length < 10){
                    return false
                    }
                    return true
                }),
                maxLength: helpers.withMessage(`El campo debe contener máximo 10 caracteres` , function(value){
                    value = value.toString().replace("-", "");
                    value = value.toString().replace(" ", "");
                    if(value.toString().length > 10) return false;
                    return true
                }),
                isDiferentToCell: helpers.withMessage(`El número de teléfono debe ser diferente al número de celular` , function(value, e){
                    if(value == "") return true;
                    
                    return value != e.owner_contact_cellphone;
                })
            },
            payer_rfc: {
                required: helpers.withMessage('El campo es requerido', requiredIf((value, e) => {
                    return e.payer == 'otra persona';
                })),
                maxlength: helpers.withMessage("El campo tiene que tener maximo 13 caracteres",maxLength(13)),
                minlength: helpers.withMessage("El campo tiene que tener maximo 13 caracteres",minLength(13)),
                structureIsValid: helpers.withMessage("El formato del RFC no es correcto. Ejemplo de formato correcto: LOMP8206281H0" , helpers.withAsync(async function (value, e) {
                    if(value.length == 0) {
                        return true;
                    }

                    let rfc = validateRfcStructure(value);
                    if(!rfc.status) return true;
                    
                    let validate = await validateStrucDatauserPayerRFC();
                    if(validate && e.payer_curp == ""){
                        e.payer_curp = value.toString().substr(0, 10);
                    }

                    return validate;
                })),
                isValid: helpers.withMessage("El formato del RFC no es correcto. Ejemplo de formato correcto: LOMP8206281H0" , helpers.withAsync(async function (value) {
                    if(value.length==0) {
                        return true;
                    }
                    let rfc = validateRfcStructure(value);
                    return rfc.status;
                }))
            },
            payer_curp: {
                required: helpers.withMessage('El campo es requerido', requiredIf((value, e) => {
                    return e.payer == 'otra persona';
                })),
                minlength: helpers.withMessage("El campo tiene que tener mínimo 18 caracteres",minLength(18)),
                maxlength: helpers.withMessage("El campo tiene que tener máximo 18 caracteres",maxLength(18)),
                structureIsValid: helpers.withMessage("El formato del CURP no es correcto. Ejemplo de formato correcto: LOMP8206281HSRTZR02" , function (value) {
                    return value == ""? true : validateCurpStructure(value.toString().toUpperCase());
                }),
                validateMatchBetweenCurpAndRfc: helpers.withMessage(`Los primeros 10 caracteres del campo CURP no coinciden con los primeros 10 caracteres del campo RFC`, function (value, e) {
                    return (e.payer_rfc != '') ? validateMatchStrings(value, e.payer_rfc): true
                }),
            },
            payer_first_name: {
                required: helpers.withMessage('El campo es requerido', function(value, e) {
                    let rfc = validateRfcStructure(e.payer_rfc);
                    if(rfc.status) {
                        validateStrucDatauserPayerRFC()
                    }
                    return !(e.payer == 'otra persona' && value == '');
                }),
                minlength: helpers.withMessage("El campo tiene que tener mínimo 2 caracteres",minLength(2)),
                maxlength: helpers.withMessage("El campo tiene que tener máximo 30 caracteres",maxLength(30)),
            },
            payer_second_name: {
                required: helpers.withMessage('El campo es requerido', function(value, e) {
                    let rfc = validateRfcStructure(e.payer_rfc);
                    if(rfc.status) {
                        validateStrucDatauserPayerRFC()
                    }
                    return true;
                }),
                minlength: helpers.withMessage("El campo tiene que tener mínimo 2 caracteres",minLength(2)),
                maxlength: helpers.withMessage("El campo tiene que tener máximo 30 caracteres",maxLength(30)),
            },
            payer_maternal_name: {
                required: helpers.withMessage('El campo es requerido', function(value, e) {
                    let rfc = validateRfcStructure(e.payer_rfc);
                    if(rfc.status) {
                        validateStrucDatauserPayerRFC()
                    }
                    return !(e.payer == 'otra persona' && value == '');
                }),
                minlength: helpers.withMessage("El campo tiene que tener mínimo 2 caracteres",minLength(2)),
                maxlength: helpers.withMessage("El campo tiene que tener máximo 30 caracteres",maxLength(30)),
            },
            payer_paternal_name: {
                required: helpers.withMessage('El campo es requerido', function(value, e) {
                    let rfc = validateRfcStructure(e.payer_rfc);
                    if(rfc.status) {
                        validateStrucDatauserPayerRFC()
                    }
                    return !(e.payer == 'otra persona' && value == '');
                }),
                minlength: helpers.withMessage("El campo tiene que tener mínimo 2 caracteres",minLength(2)),
                maxlength: helpers.withMessage("El campo tiene que tener máximo 30 caracteres",maxLength(30)),
            },
            payer_gender: {
                required: helpers.withMessage('El campo es requerido', requiredIf((value, e) => {
                    return e.payer == 'otra persona';
                }))
            },
            payer_contact_email: {
                required: helpers.withMessage('El campo es requerido', function(value, e) {
                    return e.payer != 'otra persona';
                }),
                email: helpers.withMessage(`El valor no es una dirección de correo electrónico válida` , email),
                $autoDirty: true,
                minlength: helpers.withMessage("El campo tiene que tener mínimo 5 caracteres",minLength(5)),
                maxlength: helpers.withMessage("El campo tiene que tener máximo 255 caracteres",maxLength(255)),
            },
            payer_contact_cellphone: {
                required: helpers.withMessage('El campo es requerido', requiredIf((value, e) => {
                    return e.payer == 'otra persona';
                })),
                minLength: helpers.withMessage(`El campo debe contener mínimo 10 caracteres` , function(value){
                    if(value=="") return true;

                    value = value.toString().replace("-", "");
                    value = value.toString().replace(" ", "");
                    
                    if(value.toString().length < 10){
                    return false
                    }
                    return true;
                }),
                maxLength: helpers.withMessage(`El campo debe contener máximo 10 caracteres` , function(value){
                    value = value.toString().replace("-", "");
                    value = value.toString().replace(" ", "");
                    if(value.toString().length > 10) return false;
                    return true;
                }),
            },
            payer_contact_telephone: {
                required: helpers.withMessage('El campo es requerido', requiredIf((value, e) => {
                    return e.payer == 'otra persona';
                })),
                minLength: helpers.withMessage(`El campo debe contener mínimo 10 caracteres` , function(value){
                    if(value=="") return true;

                    value = value.toString().replace("-", "");
                    value = value.toString().replace(" ", "");
                    
                    if(value.toString().length < 10){
                        return false
                    }
                    return true;
                }),
                maxLength: helpers.withMessage(`El campo debe contener máximo 10 caracteres` , function(value){
                    value = value.toString().replace("-", "");
                    value = value.toString().replace(" ", "");
                    if(value.toString().length > 10) return false;
                    return true;
                }),
                isDiferentToCell: helpers.withMessage(`El número de teléfono debe ser diferente al número de celular` , function(value, e){
                    if(value == "") return true;
                    return value != e.payer_contact_cellphone;
                })
            }
        });

        let rules_validations_birth_place_owner = {
            country:{
                required: helpers.withMessage('El campo es requerido', requiredIf(() => {
                    return creditOwnerRefs.value.creditOwnerModel.credit_owner == 'otra persona';
                }))
            },
            state:{
                required: helpers.withMessage('El campo es requerido', requiredIf(() => {
                    return creditOwnerRefs.value.creditOwnerModel.credit_owner == 'otra persona';
                }))
            },
            municipality:{
                required: helpers.withMessage('El campo es requerido', requiredIf(() => {
                    return creditOwnerRefs.value.creditOwnerModel.credit_owner == 'otra persona';
                }))
            },
            city:{
                required: helpers.withMessage('El campo es requerido', requiredIf(() => {
                    return creditOwnerRefs.value.creditOwnerModel.credit_owner == 'otra persona';
                }))
            }
        };

        let rules_validations_birth_place_payer = {
            country:{
                required: helpers.withMessage('El campo es requerido', requiredIf(() => {
                    return creditOwnerRefs.value.creditOwnerModel.payer == 'otra persona';
                }))
            },
            state:{
                required: helpers.withMessage('El campo es requerido', requiredIf(() => {
                    return creditOwnerRefs.value.creditOwnerModel.payer == 'otra persona';
                }))
            },
            municipality:{
                required: helpers.withMessage('El campo es requerido', requiredIf(() => {
                    return creditOwnerRefs.value.creditOwnerModel.payer == 'otra persona';
                }))
            },
            city:{
                required: helpers.withMessage('El campo es requerido', requiredIf(() => {
                    return creditOwnerRefs.value.creditOwnerModel.payer == 'otra persona';
                }))
            }
        };
       
        onBeforeMount(() => {
            let storage = existStorage();
            if(!storage) goToBack();

            userObjectClass.value = new User();
        });

        onMounted(async ()=> {
            app = app.appContext.config.globalProperties;
            await getAccountInformation();
            isLoading.value = false;
        });

        function getPersonId(){
            let information = getTokenDecoden();
            return information.obj.person.id;
        }

        async function getAccountInformation(){
            
            const information = await getAdmissionRequest();
            if(information.status){

                let contactInfo = information.data.admission_information.contact_info_attributes;

                let cellphone = Object.keys(contactInfo).find(element => contactInfo[element].contact_type == 'celular');
                cellphone = contactInfo[cellphone].value;

                let telephone = Object.keys(contactInfo).find(element => contactInfo[element].contact_type == 'telefono particular');
                telephone = contactInfo[telephone].value;

                let email = Object.keys(contactInfo).find(element => contactInfo[element].contact_type == 'correo');
                email = contactInfo[email].value;

                const birthday = formatDate(information.data.admission_information.birthday);

                accountInformation.value = {
                    rfc: information.data.admission_information.rfc,
                    curp: information.data.admission_information.curp,
                    gender: app.$filters.formatCamelize(information.data.admission_information.gender),
                    age: getAgeByDate(birthday),
                    birth_place: app.$filters.formatCamelize(`${information.data.admission_information.city_birth} ${information.data.admission_information.state_birth}, ${information.data.admission_information.country_birth}`),
                    cellphone: app.$filters.formatPhone(cellphone),
                    telephone: app.$filters.formatPhone(telephone),
                    email: email.toString().toLowerCase(),
                };
            }
        }

        function formatDate(date){
            let birthday = date.toString();
            birthday = birthday.split("/");
            birthday = `${birthday[2]}-${birthday[1]}-${birthday[0]}`;
            return birthday
        }

        async function getAdmissionRequest(){
            let token = getToken();
            let personId = getPersonId();
            return await getAdmissionRequestOrCreate(token, personId).then(resp => {
                return {status: true, message: resp.data.response.message, data: resp.data.response.admission_request};
            }).catch(err => { return { status: false, message: err.response.data.response.message, data: null } });
        }

        function getToken(){
            return sessionStorage.getItem('login');
        }

        function goToBack(){
            router.push({ path: '/cuenta/credito/datos' });
        }

        function existStorage(){
            return sessionStorage.getItem('credit-data');
        }

        async function validateStrucDatauserRFC(){
            
            if(creditOwnerRefs.value == null) return true;

            let paternalName = creditOwnerRefs.value.creditOwnerModel.owner_paternal_name;
            let maternalName = creditOwnerRefs.value.creditOwnerModel.owner_maternal_name;
            let firstName = creditOwnerRefs.value.creditOwnerModel.owner_first_name;
            let secondName = creditOwnerRefs.value.creditOwnerModel.owner_second_name;
            let secondsName = validateNames(paternalName, maternalName)
            let names = validateNames(firstName, secondName)
            let rfc = creditOwnerRefs.value.creditOwnerModel.owner_rfc;
            let birthday = getBirthdayByRfc(rfc);

            if(rfc !="" && birthday != "" && names && secondsName && firstName.length > 2 &&  maternalName.length > 2 &&  paternalName.length > 2){
                    if ( rfc == "" ) {
                        return true;
                    }

                    if (rfc.toString().length < 13 && !rules_validations.value.owner_rfc.structureIsValid ) {
                        return false;
                    }

                    const isValid = await validateCompleteRfc(firstName, secondName, paternalName, maternalName, rfc, birthday).then(res => {
                        return res;
                    }).catch(err => {
                        console.log("Err:", err)
                        return null;
                    });
                    
                    if(isValid != null && isValid.data.response.has_errors){
                        Swal.fire({
                            title: "Aviso",
                            html: (isValid.data.errors.length > 0)? isValid.data.errors[0] : 'Ha ocurrido un error al validar RFC',
                            icon: "error",
                        });
                        SwalAlertHelper.setIdAttributeToModalWindow('button_modal_cancel');
                        SwalAlertHelper.setIdAttributeToButtons('button_modal_ok', '');
                        
                        return false;
                    }
                    
                    return true;
            }

            return true;
        }

        async function validateStrucDatauserPayerRFC(){
            
            if(creditOwnerRefs.value == null) return true;

            let paternalName = creditOwnerRefs.value.creditOwnerModel.payer_paternal_name;
            let maternalName = creditOwnerRefs.value.creditOwnerModel.payer_maternal_name;
            let firstName = creditOwnerRefs.value.creditOwnerModel.payer_first_name;
            let secondName = creditOwnerRefs.value.creditOwnerModel.payer_second_name;
            let secondsName = validateNames(paternalName, maternalName)
            let names = validateNames(firstName, secondName)
            let rfc = creditOwnerRefs.value.creditOwnerModel.payer_rfc;
            let birthday = getBirthdayByRfc(rfc);

            if(rfc != "" && birthday!="" && names && secondsName && firstName.length > 2 &&  maternalName.length > 2 &&  paternalName.length > 2){
                    if ( rfc == "" ) {
                        return true;
                    }

                    if (rfc.toString().length < 13 && !rules_validations.value.payer_rfc.structureIsValid ) {
                        return false;
                    }

                    const isValid = await validateCompleteRfc(firstName, secondName, paternalName, maternalName, rfc, birthday).then(res => {
                        return res;
                    }).catch(err => {
                        console.log("Err:", err)
                        return null;
                    });
                    
                    if(isValid != null && isValid.data.response.has_errors){
                        Swal.fire({
                            title: "Aviso",
                            html: (isValid.data.errors.length > 0)? isValid.data.errors[0] : 'Ha ocurrido un error al validar RFC',
                            icon: "error",
                        });
                        SwalAlertHelper.setIdAttributeToModalWindow('button_modal_cancel');
                        SwalAlertHelper.setIdAttributeToButtons('button_modal_ok', '');
                        
                        return false;
                    }
                    
                    return true;
            }

            return true;
        }

        function validateRfcStructure(rfc) {
            rfc = rfc.toUpperCase();
            let rfc_pattern =
                "^(([A-ZÑ&]{4})([0-9]{2})([0][13578]|[1][02])(([0][1-9]|[12][\\d])|[3][01])([A-Z0-9]{3}))|" +
                "(([A-ZÑ&]{4})([0-9]{2})([0][13456789]|[1][012])(([0][1-9]|[12][\\d])|[3][0])([A-Z0-9]{3}))|" +
                "(([A-ZÑ&]{4})([02468][048]|[13579][26])[0][2]([0][1-9]|[12][\\d])([A-Z0-9]{3}))|" +
                "(([A-ZÑ&]{4})([0-9]{2})[0][2]([0][1-9]|[1][0-9]|[2][0-8])([A-Z0-9]{3}))$";
            const coincidences = rfc.match(rfc_pattern);
            const data = [];
            if (coincidences != null) {
                coincidences.forEach((element) => {
                if (typeof element != "undefined") {
                    data.push(element);
                }
                });
            }
            return coincidences == null
                ? { status: false, coincidences: data }
                : { status: true, coincidences: data };
        }

        async function validateCompleteRfc(first_name, second_name, paternal_name, maternal_name, rfc, birthday) {
            try {
                let seconName= validateNames( paternal_name,  maternal_name)
                let names= validateNames( first_name,  second_name)
                if( rfc != "" && birthday != "" && names && seconName ){
                    return await userObjectClass.value
                        .rfcFormatIsValid({
                            rfc:  rfc,
                            dateBirth:  birthday,
                            firstName:  first_name,
                            secondName:  second_name,
                            paternalName:  paternal_name,
                            maternalName:  maternal_name,
                        })
                        .then((res) => {
                            return res;
                        })
                        .catch((err) => {
                            return err;
                        });
                }
            } catch (error) {
                return false;
            }
        }

        function validateCurpStructure(curp) {
            let curp_pattern =
                /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/;
            const coincidences = curp.match(curp_pattern);
            return coincidences == null ? false : true;
        }

        const validateMatchStrings = (str, matchStr) => {
            str = str.toString().trim().toUpperCase().substr(0, 10);
            matchStr = matchStr.toString().trim().toUpperCase().substr(0, 10);
            return str === matchStr;
        }

        function getBirthdayByRfc(value) {
            //TODO: cambiar este metodo por servicio get_date_by_rfc
            var m = value.match( /^\w{4}(\w{2})(\w{2})(\w{2})/ );
            var year = parseInt(m[1],10)+1900;
            if( year < 1925 ) year += 100;
            var month = parseInt(m[2], 10);
            var day = parseInt(m[3], 10);
            console.log("Fecha:", `${year}-${month}-${day}`);
            return `${year}-${month}-${day}`;
        }

        function validateNames(firstName,secondName){
            if(firstName!="" || secondName!="") {
                return true
            }else{
                return false
            }
        }

        function next(route){
            let creditOwner = creditOwnerRefs.value.execute_validation();
            if(creditOwner){
                createStorage(creditOwner);
                router.push({ path: route });
                return;
            }
        }

        function getAgeByDate(date){
            const birthday = moment(date);
            const currentDate = moment();
            return currentDate.diff(birthday, 'years');
        }

        function createStorage(data){
            sessionStorage.setItem('credit-owner', JSON.stringify(data));
        }

        return { 
            isLoading,
            view_class_view,
            rules_validations,
            rules_validations_birth_place_owner,
            rules_validations_birth_place_payer,
            creditOwnerRefs,
            next,
            accountInformation
        };
    },
    components: {
        DetailComponent,
        Loading
    }

}

</script>

<style scoped>
p {
    color: #485462;
}
</style>